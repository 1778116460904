import { createWebHistory, createRouter } from "vue-router";
//import Test from "./Test.vue";
import Main from "./views/Main.vue";
import Result from "./views/Result.vue";
import MkNicknm from "./views/MkNicknm.vue";
import Question from "./views/Question.vue";
import Error from "./views/Error.vue";
const routes = [
  {
    path: "/qna",
    component: Question,
  },
  {
    path: "/nicknm",
    component: MkNicknm,
  },
  {
    path: "/result/:a_id",
    component: Result,
  },

  {
    path: "/",
    component: Main,
  },
  {
    path: "/error",
    name: "error",
    component: Error,
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/error",
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
