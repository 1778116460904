<template>
  <div>총선까지 D-{{ dateGap }}</div>
  <div id="title-label">2024 총선 정책으로 알아보는</div>
  <div class="title">당BTI</div>
  <div class="folder-line">
    <div class="folder"><img src="../assets/img/folder-line-03.png" /></div>
    <div>
      <span class="party-txt txt-noend"> 더불어민주당 </span>
      <span class="party-txt txt-noend"> ??? </span>
      <span class="party-txt"> 개혁신당 </span>
    </div>
  </div>
  <div class="folder-line">
    <div class="folder"><img src="../assets/img/folder-line-04.png" /></div>
    <div>
      <span class="party-txt txt-noend"> ??? </span>
      <span class="party-txt txt-noend"> ??? </span>
      <span class="party-txt"> ??? </span>
    </div>
  </div>
  <div class="folder-line">
    <div class="folder"><img src="../assets/img/folder-line-05.png" /></div>
    <div>
      <span class="party-txt txt-noend"> 국민의힘 </span>
      <span class="party-txt txt-noend"> ??? </span>
      <span class="party-txt"> 새진보연합 </span>
    </div>
  </div>
  <button class="btn-white" @click="goNick">시작하기</button>
</template>

<script>
import { useRouter } from "vue-router";

export default {
  setup() {
    const router = useRouter();
    const today = new Date();
    const dday = new Date(2024, 3, 10);
    const timeGap = dday.getTime() - today.getTime();

    console.log(today);
    console.log(dday);
    const dateGap = Math.ceil(timeGap / (1000 * 60 * 60 * 24));
    const goNick = () => {
      router.push({
        path: "/nicknm",
      });
    };
    return { goNick, dateGap };
  },
};
</script>
