<template>
  <div id="result" v-if="!loading">
    <div class="h3">
      {{ tester }}님의 당BTI는...<br />
      <span :class="'txt-' + p_id" v-for="p_id in f_a_party">{{
        p_id == "1"
          ? "더불어민주당"
          : p_id == "2"
          ? "국민의힘"
          : p_id == "3"
          ? "새진보연합"
          : p_id == "4"
          ? "개혁신당"
          : ""
      }}</span
      >입니다
    </div>
    <div style="width: 90%; margin: auto auto 20px">
      <div class="">
        <BarChart v-bind:result="result" v-bind:total_value="total_value" />
      </div>
    </div>
    <div class="h5">
      <p>
        <span :class="'txt-' + p_id" v-for="p_id in f_a_party">{{
          p_id == "1"
            ? "더불어민주당"
            : p_id == "2"
            ? "국민의힘"
            : p_id == "3"
            ? "새진보연합"
            : p_id == "4"
            ? "개혁신당"
            : ""
        }}</span
        >의 총선 공약 중 {{ tester }}님이 선택한 공약은
      </p>
    </div>
    <div
      style="
        border: 1px solid var(--STR2, rgba(170, 170, 170, 0.72));
        background: #fff;

        box-shadow: -2px -2px 0px 2px rgba(61, 61, 61, 0.21) inset,
          4px 4px 0px 0px #7a7a7a inset;
        height: 50vh;
        overflow: scroll;
        padding: 10px;
      "
    >
      <div
        style="text-align: left"
        v-for="x in a_list.filter(
          (x) =>
            x.p_id == f_a_party[0] ||
            x.p_id == f_a_party[1] ||
            x.p_id == f_a_party[2]
        )"
      >
        ({{ x.q_txt }}) {{ x.e_txt }}
      </div>
    </div>
    <br />
    <div class="h5">
      <p>22대 총선 공약을 더 알아보고 싶으신가요?</p>
    </div>
    <div class="btn-margin">
      <a id="btnTwitter" class="link-icon party00" @click="goParty('00')"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </a>
      <a id="btnFacebook" class="link-icon party01" @click="goParty('01')"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </a>
      <a id="btnKakao" class="link-icon party02" @click="goParty('02')"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </a>
      <a id="btnLink" class="link-icon party03" @click="goParty('03')"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </a>
      <div><button class="btn-white" @click="goNotion">공약정리</button></div>
    </div>
    <div class="h5">
      <p>결과를 공유하고 싶으신가요?</p>
    </div>
    <div class="btn-margin">
      <a id="btnTwitter" class="link-icon twitter" @click="shareTwitter()"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </a>
      <a id="btnFacebook" class="link-icon facebook" @click="shareFacebook()"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </a>
      <a id="btnKakao" class="link-icon kakao" @click="shareKakao()"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </a>
      <a id="btnLink" class="link-icon link" @click="shareLink()"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </a>
      <div>
        <button class="btn-white" @click="goMain">
          내 당BTI<br />알아보기
        </button>
      </div>
    </div>
    <div>
      ※해당 결과는 정치찌개가 부여한 가산점이 포함된 것으로, 선택한 보기의
      가중치 적용 여부에 따라 상이한 결과를 받아보실 수 있습니다. 자세한 사항은
      아래 정치찌개 노션 페이지를 방문해주시면 확인하실 수 있습니다.
    </div>
  </div>
  <LoadingBar :loading="loading"></LoadingBar>
</template>
<script>
import BarChart from "../views/BarChart.vue";
import { onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import LoadingBar from "@/views/common/LoadingBar.vue";

import Api from "../api/api";
export default {
  components: { BarChart, LoadingBar },
  setup() {
    const loading = ref(true);
    const router = useRouter();
    const route = useRoute();

    const a_id = route.params.a_id;

    let a_list = ref([]);
    let f_a_list = ref([]);
    let f_a_party = ref([]);
    let tester = ref("");
    let result = ref({});
    let total_value = ref(0);

    const goNotion = () => {
      location.href =
        "https://www.notion.so/796a39f2601540a887d4eaca0069d17f?pvs=4";
    };
    // Facebook
    const shareFacebook = () => {
      const title = "DBTI - 나와 맞는 정당은? - ";
      const url = window.location.href;
      window.open(
        "http://www.facebook.com/sharer/sharer.php?u=" + url + "&title=" + title
      );
    };

    // Twitter
    const shareTwitter = () => {
      const text = "DBTI - 나와 맞는 정당은? - ";
      const url = window.location.href;
      window.open("https://twitter.com/intent/tweet?text=" + text + url);
    };
    const shareKakao = () => {
      Kakao.Share.sendDefault({
        objectType: "feed",
        content: {
          title: "DBTI - 나와 맞는 정당은?",
          description: "정당별 정책을 통해 나의 DBTI를 찾아봐요!",
          imageUrl: `@/assets/img/thumbnail.png`,
          link: {
            mobileWebUrl: window.location.href,
            webUrl: window.location.href,
          },
        },
      });
    };
    const shareLink = () => {
      var url = "";
      var textarea = document.createElement("textarea");
      document.body.appendChild(textarea);
      url = window.location.href;
      textarea.value = url;
      textarea.select();
      document.execCommand("copy");
      document.body.removeChild(textarea);
      alert("링크가 복사되었습니다.");
    };
    const goParty = (party) => {
      if (party == "00") {
        window.open("https://rallypoint.kr/main");
      } else if (party == "01") {
        window.open("https://www.peoplepowerparty.kr/");
      } else if (party == "02") {
        window.open("https://theminjoo.kr/main/");
      } else if (party == "03") {
        window.open("https://www.newjinbo.kr/");
      }
    };
    const goMain = () => {
      router.push({
        path: "/",
      });
    };
    // 최초 한 번만 가져오기

    Api({
      method: "post",
      url: "/selectAnswer",
      data: { a_id: a_id },
    }).then(function (response) {
      a_list.value = response.data.a_list;

      a_list.value.forEach((x) => {
        let e_point = (x.e_point *= 1);
        result.value[x.p_id] = (result.value[x.p_id] || 0) + e_point;

        total_value.value += e_point;
      });
      var arr = Object.keys(result.value).map(function (key) {
        return result.value[key];
      });
      var max = Math.max.apply(null, arr);

      const keysOfResult = Object.keys(result.value);
      f_a_party.value = keysOfResult.filter((key) => result.value[key] === max);

      tester.value = a_list.value[0].tester;
      loading.value = false;
      Kakao.init("167e144c2ed6fbfccfdb848fa2a7d3f1");
    });

    return {
      a_list,
      tester,
      result,
      f_a_party,
      total_value,
      shareTwitter,
      shareFacebook,
      shareKakao,
      shareLink,
      goParty,
      goMain,
      goNotion,
      loading,
    };
  },
};
</script>
