<template>
  <div class="txt-margin"><h2>닉네임을 입력해주세요</h2></div>
  <div style="width: 90%; margin: auto auto 20px">
    <div class="">
      <form v-on:submit="onSubmitForm">
        <!-- v-model input에서 값을 받아올 이름 지정 -->
        <input
          type="text"
          class="input-nick"
          v-model="nick"
          maxlength="8"
          minlength="2"
        />
      </form>
    </div>

    <span class="nick-guide">닉네임은 최소 2글자, 최대 8글자 작성 가능</span>
    <div class="disclaimer">
      <div class="disclaimer-text">
        <p>
          ※ 본 테스트는 제 22대 국회의원 선거를 앞두고 제작된 척도로, 측정값이
          응답자의 정치사회적 견해와
          <span class="txt-underline">일부 일치하지 않을 수 있습니다.</span>
        </p>
        <p>
          ※ 테스트의 질문들은
          <span class="txt-underline">각 정당의 정강정책과 총선공약</span>을
          바탕으로 작성되었습니다.
        </p>
        <p>※ <span class="txt-underline">제22대 국회의원 선거</span> 일정</p>
        <p>
          <b
            >- 사전투표: 2024년 4월 5일 (금) ~ 6일 (토)<br />
            - 본투표: 2024년 4월 10일 (수)<br />
            - 투표 시간: 매일 오전 6시 ~ 오후 6시<br />
            - 재외국민 투표: 2024년 3월 27일 (수) ~ 4월 1일 (월) 오전 8시 ~ 오후
            5시</b
          >
        </p>
        <p>
          * 자세한 사항은 중앙
          <a href="https://m.nec.go.kr/site/nec/main.do">선관위 홈페이지</a>에서
          확인하여 주시길 바랍니다.
        </p>
        <p>
          * 본 테스트의 결과는 상업적 용도가 아닌 단순 분석 및 통계를 위해
          수집•저장됩니다.
        </p>
      </div>
    </div>
    <button class="btn-white" @click="goQna">테스트하러가기</button>
  </div>
    
</template>

<script>
import { useRouter } from "vue-router";
import { ref } from "vue";

export default {
  setup() {
    const router = useRouter();
    const nick = ref(null);

    const goQna = () => {
      const validateId = /^[A-Za-z가-힣]{2,8}$/;
      if (nick.value == "" || nick.value == null) {
        alert("닉네임을 입력해주세요.");
        return;
      } else if (!validateId.test(nick.value)) {
        alert("닉네임을 영문, 한글로만 2자이상 8자 이하로 입력해주세요. ");
        return;
      }
      router.push({
        path: "/qna",
        state: {
          tester: nick.value,
        },
      });
    };
    return { goQna, nick };
  },
};
</script>
