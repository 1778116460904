<template>
  <div style="width: 90%; margin: auto auto 20px">
    <div class="">
      <div class="">오류 페이지 입니다.</div>
      <br />
      <br />
      <br />
      <button class="" @click="goMain">메인으로 가기</button>
    </div>
  </div>
    
</template>

<script>
import { useRouter } from "vue-router";
import { ref } from "vue";

export default {
  setup() {
    const router = useRouter();

    const goMain = () => {
      router.push({
        path: "/",
      });
    };
    return { goMain };
  },
};
</script>
