<template>
  <div v-if="!loading">
    <div id="d-progress">
      <div id="progress-bar" :style="{ width: widPer + '%' }"></div>
      <div id="progress-txt">{{ currentIdx + 1 }} / {{ q_cnt }} 문제</div>
    </div>
    <h5
      style="width: 90%; margin: 20px auto"
      class="txt-margin"
      v-if="q_list != null && e_list != null"
    >
      다음중 가장 끌리는
      <span class="txt-underline">{{ q_list[currentIdx].q_txt }}</span> 공약을
      골라주세요.
    </h5>
    <div style="width: 90%; margin: 10px auto">
      본 테스트는 한 문항 당 선호하는 정책을 반드시 선택하는 것을 기본으로 하며
      해당 분야의 정책을 내지 않은 정당의 보기는 ‘없음’으로 표기하였습니다.
    </div>
    <div
      style="width: 90%; margin: auto auto 20px"
      v-if="q_list != null && e_list != null"
    >
      <div
        class="qna-btn"
        v-for="x in e_list
          .filter((x) => x.q_seq == q_list[currentIdx].q_seq)
          .sort(() => Math.random() - 0.5)"
        @click="next(x.q_seq, x.e_seq)"
      >
        <div class="btn-txt">{{ x.e_txt }}</div>
      </div>
      <div class="bottom-btn" @click="prev(q_list[currentIdx].q_seq)">
        뒤로가기
      </div>
      <!-- <div class="bottom-btn left-btn" @click="prev">◀</div>
    <div class="bottom-btn" @click="goMain">🏠︎</div>
    <div class="bottom-btn right-btn" @click="next">▶</div> 240309 홈 다음 이전 버튼 가리기-->
    </div>
  </div>
  <LoadingBar :loading="loading"></LoadingBar>
</template>

<script>
import { computed, onMounted, ref } from "vue";
import { useRouter } from "vue-router";

import LoadingBar from "@/views/common/LoadingBar.vue";
import Api from "../api/api";
export default {
  components: {
    LoadingBar,
  },
  setup() {
    const loading = ref(true);

    const router = useRouter();
    const currentIdx = ref(0);
    const tester = history.state.tester;

    let q_list = ref(null);
    let e_list = ref(null);
    let a_list = ref([]);
    let q_cnt = ref(0);
    let widPer = ref("0");

    // 최초 한 번만 가져오기

    Api({
      method: "post",
      url: "/qna",
    }).then(function (response) {
      q_list.value = response.data.q_list;
      e_list.value = response.data.e_list;
      q_cnt.value = response.data.q_list.length;
      widConc(currentIdx.value);
      loading.value = false;
    });

    const goMain = () => {
      router.push({
        path: "/",
      });
    };

    const widConc = (idx) => {
      widPer.value = ((idx + 1) / q_cnt.value) * 100;
    };

    const next = (qSeq, eSeq) => {
      // console.log("질문시퀀스" + qSeq);
      // console.log("당시퀀스" + pId);
      // console.log(a_list);
      a_list.value.push({ q_seq: qSeq, e_seq: eSeq, tester: tester });
      if (currentIdx.value < q_list.value.length - 1) {
        if (
          typeof q_list.value[currentIdx.value + 1] != "undefined" &&
          q_list.value[currentIdx.value + 1] != null
        ) {
          currentIdx.value++;
          widConc(currentIdx.value);
        }
      } else {
        var check = true;
        for (var i = 0; i < q_list.length; i++) {
          if (q_list[i].r === "") {
            check = false;
          }
        }
        if (check) {
          // alert("결과이동.");
          Api({
            method: "post",
            url: "/insertAnswer",
            data: a_list.value,
          }).then(function (response) {
            router.push({
              path: "/result/" + response.data,
            });
          });
        } else {
          alert("아직 완료되지 않았습니다.");
        }
      }
    };
    const prev = (q_seq) => {
      console.log(a_list.value);
      if (currentIdx.value > 0) {
        if (
          typeof q_list.value[currentIdx.value - 1] != "undefined" &&
          q_list.value[currentIdx.value - 1] != null
        ) {
          a_list.value = a_list.value.filter(
            (param) => param.q_seq != q_seq - 1
          );
          currentIdx.value--;
          widConc(currentIdx.value);
        }
      } else {
        alert("첫 질문입니다.");
      }
    };
    return {
      goMain,
      currentIdx,
      next,
      prev,
      q_list,
      e_list,
      a_list,
      q_cnt,
      widPer,
      loading,
    };
  },
};
</script>
