<template>
  <Bar
    id="my-chart-id"
    :options="chartOptions"
    :data="chartData"
    :height="50"
  />
</template>

<script>
import { Bar } from "vue-chartjs";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
);

export default {
  name: "BarChart",
  props: ["result", "total_value"],
  components: { Bar },
  computed: {
    chartData: function () {
      let sorted = Object.entries(this.result).sort((a, b) => b[1] - a[1]);
      var chartData = [];
      for (let element of sorted) {
        chartData.push({
          label:
            element[0] == "1"
              ? "더불어민주당"
              : element[0] == "2"
              ? "국민의힘"
              : element[0] == "3"
              ? "새진보연합"
              : element[0] == "4"
              ? "개혁신당"
              : "",
          backgroundColor:
            element[0] == "1"
              ? "#000080"
              : element[0] == "2"
              ? "#D62124"
              : element[0] == "3"
              ? "#00D2C3"
              : element[0] == "4"
              ? "#FF7F00"
              : "",
          data: [element[1]],
        });
      }
      return {
        labels: [""],
        datasets: chartData,
      };
    },
    chartOptions: function () {
      return {
        responsive: true,
        indexAxis: "y",
        plugins: {
          legend: {
            display: false,
          },
          datalabels: {
            display: false,
          },
          tooltip: {
            boxWidth: 11,
          },
        },
        scales: {
          x: {
            stacked: true,
            max: this.total_value,
          },
          y: {
            stacked: true,
          },
        },
      };
    },
  },
};
</script>
