<template>
  <div id="topbar">
    <span class="txt-white">2024 총선 기념 테스트</span>
    <span id="xbtn">X </span>
  </div>
  <div style="text-align: center">
    <router-view></router-view>
  </div>
  <div class="bottom">
    Copyright © 2024 by
    <a
      href="https://plaid-agustinia-00d.notion.site/796a39f2601540a887d4eaca0069d17f"
      >정치찌개</a
    >. All Rights Reserved.
  </div>
</template>

<script>
export default {
  name: "App",
  components: {},
};
</script>

<style>
@import url("../assets/css/style.css");
</style>
