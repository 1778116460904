import Axios from "axios";
//import { store } from "@/store/store";

// 1. 기본 설정 추가
const AxiosInst = Axios.create({
  baseURL: "/api", // baseURL 추가수정
});

AxiosInst.interceptors.request.use((config) => {
  config.headers.authorization = "token";
  config.headers["Access-Control-Allow-Origin"] = "*"; // CORS 설정(모든 리소스 허용)
  return config;
});
// // 2. 모든 요청을 intercept 해서 header값 설정(토큰)
// // : Axios request일때 이 설정값을 사용한다는 것
// AxiosInst.interceptors.request.use((config) => {
//   let access_token = store.getters.getToken;
//   if (access_token) {
//     config.headers.Authorization = `${access_token}`;
//   }
//   return config;
// });
export default AxiosInst;
