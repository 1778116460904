import { createStore } from "vuex";

export default createStore({
  state: {
    loading: false,
  },
  getters: {
    getLoading(state) {
      return state.loading;
    },
  },
  mutations: {
    setLoading(state, value) {
      state.loading = value;
    },
  },
});
